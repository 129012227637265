import { gql } from '@apollo/client';
import {
  BASE_DATASET,
  DATASET_FRAGMENT,
  DATASET_TABLE_REPRESENTATION_FRAGMENT,
} from 'graphql/fragments/dataset.fragments';
import {
  COLLECTION_SITE_FRAGMENT,
  DISTRIBUTION_SITE_FRAGMENT,
  FACTORY_SITE_FRAGMENT,
  FARM_SITE_FRAGMENT,
  FOREST_SITE_FRAGMENT,
  GENERIC_SITE_FRAGMENT,
  GROCERY_STORE_SITE_FRAGMENT,
  HANDLING_SITE_FRAGMENT,
  MANUFACTURING_SITE_FRAGMENT,
  MINE_SITE_FRAGMENT,
  ORIGIN_SITE_FRAGMENT,
  PLANT_SITE_FRAGMENT,
  PROCESSING_SITE_FRAGMENT,
  SITE_CLUSTER_FRAGMENT,
  WAREHOUSE_SITE_FRAGMENT,
} from 'graphql/fragments/site.fragments';

/**
 * Backend can currently only return the dataset with the table representation on the processing mutation response.
 * So we are adding the tableRepresentation field into the dataset cache object and we are using the @client directive to fetch it.
 */
export const GET_DATASET = gql`
  query GetDataset($id: UUID!) {
    dataset(id: $id) {
      ...datasetValues
      tableRepresentation {
        ...datasetTableRepresentationValues
      }
    }
  }
  ${DATASET_FRAGMENT}
  ${DATASET_TABLE_REPRESENTATION_FRAGMENT}
`;

export const GET_DATASETS = gql`
  query GetDatasets($filters: DatasetsFilters) {
    datasets(filters: $filters) {
      edges {
        node {
          ...baseDatasetValues
        }
      }
    }
  }
  ${BASE_DATASET}
`;

export const GET_DATASET_SITES = gql`
  query GetDatasetSites($datasetId: UUID!, $first: Int, $after: String, $before: String) {
    dataset(id: $datasetId) {
      id
      sites(first: $first, after: $after, before: $before) {
        edges {
          node {
            ... on GenericSite {
              ...genericSiteValues
            }
            ... on Farm {
              ...farmSiteValues
            }
            ... on Mine {
              ...mineValues
            }
            ... on Forest {
              ...forestValues
            }
            ... on CollectionSite {
              ...collectionSiteValues
            }
            ... on OriginSite {
              ...originSiteValues
            }
            ... on Plant {
              ...plantValues
            }
            ... on ProcessingSite {
              ...processingSiteValues
            }
            ... on Factory {
              ...factoryValues
            }
            ... on ManufacturingSite {
              ...manufacturingSiteValues
            }
            ... on Warehouse {
              ...warehouseValues
            }
            ... on HandlingSite {
              ...handlingSiteValues
            }
            ... on GroceryStore {
              ...groceryStoreValues
            }
            ... on DistributionSite {
              ...distributionSiteValues
            }
            ... on SiteCluster {
              ...siteClusterValues
            }
          }
        }
        count
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;
