import { useQuery } from '@apollo/client';
import { Box, ButtonGroup, styled } from '@mui/material';
import CultivationFarmArea from 'components/CultivatedFarmArea/CultivationFarmArea';
import { Loader } from 'components/Forms';
import { useDialog } from 'components/hooks';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { ErrorState, FlexBox, PageSubTitle } from 'components/Structure';
import InfiniteScrollWrapper from 'components/Structure/InfiniteScrollWrapper';
import { appQueryParams } from 'constants/appQueryParams';
import { CULTIVATION_AREAS_PER_PAGE, SITES_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import { InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_DATASET_SITES } from 'graphql/queries/dataset.queries';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { DatasetView, IDataset } from 'types/dataset.types';
import { IFarmSite, ISite, SiteType } from 'types/site.types';
import { GraphQlConnection } from 'types/types';
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphql.utils';
import PlotWarningBanner from '../PlotWarningBanner';
import SiteItem from '../SiteItem';

interface IDatasetVerificationProps {
  dataset: IDataset;
}

const Container = styled(Box)({
  width: '100%',
});

const DatasetVerification: FC<IDatasetVerificationProps> = ({ dataset }) => {
  const [viewOption, setViewOption] = useQueryParam(
    appQueryParams.viewOption,
    withDefault(createEnumParam<DatasetView>([DatasetView.LIST, DatasetView.MAP]), DatasetView.LIST)
  );

  const { data, loading, error, fetchMore } = useQuery<{
    dataset: { sites: GraphQlConnection<ISite> };
  }>(GET_DATASET_SITES, {
    variables: {
      datasetId: dataset.id,
      first:
        viewOption === DatasetView.MAP ? CULTIVATION_AREAS_PER_PAGE : SITES_TABLE_ROWS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });
  const { openDialog } = useDialog();
  const { deleteSite } = useSiteMutations();

  // Stores the last page of to which the user can navigate (on the page the we received hasNextPage as true)
  const [lastPage, setLastPage] = useState<number | null>(null);
  const [page, setPage] = useState(0);

  const farms: IFarmSite[] = useMemo(
    () =>
      data?.dataset.sites
        ? (removeGraphConnections(data.dataset.sites).filter(
            site => site.siteType === SiteType.FARM
          ) as IFarmSite[])
        : [],
    [data]
  );

  const farmsOnCurrentPage: IFarmSite[] = useMemo(
    () =>
      viewOption === DatasetView.MAP
        ? farms.slice(page * CULTIVATION_AREAS_PER_PAGE, (page + 1) * CULTIVATION_AREAS_PER_PAGE)
        : [],
    [farms, page, viewOption]
  );

  useEffect(() => {
    setPage(0);
  }, [viewOption]);

  const hasNextPage: boolean = !!data?.dataset.sites?.pageInfo?.hasNextPage;
  const endCursor: string | undefined = data?.dataset.sites?.pageInfo?.endCursor;
  const totalSites = data?.dataset.sites?.count || 0;

  useEffect(() => {
    setLastPage(prev => (hasNextPage ? page : prev));
  }, [hasNextPage, page]);

  const handlePageEndReached = async () => {
    if (endCursor) {
      await fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            dataset: {
              ...prev.dataset,
              sites: {
                ...fetchMoreResult.dataset.sites,
                edges: [...prev.dataset.sites.edges, ...fetchMoreResult.dataset.sites.edges],
              },
            },
          };
        },
      });
    }
  };

  const handleNextPageClick = async () => {
    // Check if the next page is already available if not fetch more with the last endCursor
    if (farms.length <= (page + 1) * CULTIVATION_AREAS_PER_PAGE) {
      await handlePageEndReached();
    }
    setPage(currentPage => currentPage + 1);
  };

  const handlePreviousPageClick = () => {
    if (page > 0) {
      setPage(currentPage => currentPage - 1);
    }
  };

  const warningTypes = useMemo(() => {
    const issueCounts: { errorMessage: string; count: number }[] = [];
    dataset.issues?.forEach(error => {
      const existingIssues = issueCounts.find(e => e.errorMessage === error.errorMessage);
      if (existingIssues) {
        existingIssues.count += 1;
      } else {
        issueCounts.push({ errorMessage: error.errorMessage, count: 1 });
      }
    });
    return issueCounts;
  }, [dataset.issues]);

  const handleEditSite = (site: ISite) => {
    if (!site) {
      console.warn('No site to edit');
      return;
    }

    openDialog({
      type: 'ADD_EDIT_SITE',
      props: { site, partner: dataset.ownedBy },
    });
  };

  const handleEditCultivationArea = (site?: ISite) => {
    if (!site) {
      console.warn('No site to edit cultivation area');
      return;
    }

    openDialog({
      type: 'ADD_EDIT_CULTIVATION_AREA',
      props: {
        site,
      },
    });
  };

  const handleDeleteSite = (site: ISite) => {
    if (!site) {
      console.warn('No site to delete');
      return;
    }

    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site',
        text: 'Are you sure you want to delete this site? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: site.title,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({
            variables: { id: site.id },
            update: (cache, { data }) => {
              if (!data) return;

              const existingSites = cache.readQuery<{
                dataset: { sites: GraphQlConnection<ISite> };
              }>({
                query: GET_DATASET_SITES,
                variables: {
                  datasetId: dataset.id,
                },
              });

              const newSites = existingSites?.dataset.sites.edges.filter(
                ({ node }) => node.id !== data?.deleteSite.id
              );

              cache.writeQuery({
                query: GET_DATASET_SITES,
                data: {
                  dataset: {
                    ...existingSites?.dataset,
                    sites: {
                      ...existingSites?.dataset.sites,
                      edges: newSites,
                    },
                  },
                },
                variables: {
                  datasetId: dataset.id,
                },
              });
            },
          });
        },
        onCancel: () => undefined,
      },
    });
  };

  if (loading && !farms.length) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <>
      {dataset.issues.length > 0 && (
        <Box display="flex" flexDirection="column" gap={1} mb={2}>
          <PlotWarningBanner plotWarnings={warningTypes} warningCount={dataset.issues.length} />
        </Box>
      )}

      <FlexBox mb={2} gap={2}>
        <PageSubTitle title="Farms in dataset" />
        <FlexBox>
          <ButtonGroup>
            <ThemeButton
              className={viewOption === DatasetView.MAP ? 'selected' : ''}
              onClick={() => setViewOption(DatasetView.MAP)}
            >
              Map view
            </ThemeButton>
            <ThemeButton
              className={viewOption === DatasetView.LIST ? 'selected' : ''}
              onClick={() => setViewOption(DatasetView.LIST)}
            >
              List view
            </ThemeButton>
          </ButtonGroup>
          <InfoTooltip text="Select to view the farm plots in a list or on the map. Both views allows editing the farm data (e.g. farm size) or the farm plot cultivation area." />
        </FlexBox>
        <FlexBox gap={1}>
          <Icon size="large" name="farm" />
          <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
            Showing farms{' '}
            {viewOption === DatasetView.MAP ? page * CULTIVATION_AREAS_PER_PAGE + 1 : 1}-
            {viewOption === DatasetView.MAP
              ? page * CULTIVATION_AREAS_PER_PAGE + farmsOnCurrentPage.length
              : farms.length}{' '}
            of {totalSites}
          </ThemeTypography>

          {viewOption === DatasetView.MAP && !!lastPage && (
            <InfoTooltip
              text={`Due to browser capacity, maximum ${CULTIVATION_AREAS_PER_PAGE} farm plots can be shown at the same time. Farm plots with warnings are prioritised first and shown on the first page. Click to load the next page of farms.`}
            />
          )}
        </FlexBox>

        {page !== 0 && viewOption === DatasetView.MAP && (
          <ThemeButton color="WHITE" onClick={handlePreviousPageClick}>
            Previous farms
          </ThemeButton>
        )}

        {viewOption === DatasetView.MAP && lastPage !== null && page <= lastPage && (
          <ThemeButton color="WHITE" loading={loading} onClick={handleNextPageClick}>
            Load next
          </ThemeButton>
        )}
      </FlexBox>

      <Container>
        {farms.length > 0 && viewOption === DatasetView.LIST && (
          <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
            {farms.map(site => {
              const warningsOfSite = dataset.issues.filter(issue => issue.entityId === site.id);
              return (
                <SiteItem
                  key={site.id}
                  site={site}
                  warnings={warningsOfSite}
                  onEdit={handleEditSite}
                  onEditCultivationArea={handleEditCultivationArea}
                  onDelete={handleDeleteSite}
                />
              );
            })}
          </InfiniteScrollWrapper>
        )}

        {farms.length > 0 && viewOption === DatasetView.MAP && (
          <Box height="calc(100vh - 500px)" overflow="auto">
            <CultivationFarmArea
              farms={farmsOnCurrentPage}
              warnings={dataset.issues}
              onEditCultivatedAreaClick={id =>
                handleEditCultivationArea(farms.find(farm => farm.id === id))
              }
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default DatasetVerification;
