import { GeoCBatch, RiskLevelType } from './compliance.types';
import { IRawMaterial } from './component.types';
import { IBasePartnerCompany } from './partner.types';
import { ICultivatedAreas } from './site.types';
import { GraphQlConnection } from './types';

export enum DatasetStatus {
  CREATED = 'CREATED',
  PARSED = 'PARSED',
  PARSING_FAILED = 'PARSING_FAILED',
  MAPPED = 'MAPPED',
  MAPPING_FAILED = 'MAPPING_FAILED',
  VALIDATED = 'VALIDATED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  NORMALISED = 'NORMALISED',
  NORMALISATION_WITH_WARNINGS = 'NORMALISED_WITH_WARNINGS',
  NORMALISATION_FAILED = 'NORMALISATION_FAILED',
}

export enum DatasetColumn {
  FARM_ID = 'FARM_ID',
  FARM_OWNER = 'FARM_OWNER',
  FARM_NAME = 'FARM_NAME',
  COORDINATES_LAT_LNG = 'COORDINATES_LAT_LNG',
  COORDINATES_LNG_LAT = 'COORDINATES_LNG_LAT',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  COORDINATES_DD = 'COORDINATES_DD',
  LATITUDE_DD = 'LATITUDE_DD',
  LONGITUDE_DD = 'LONGITUDE_DD',
  COORDINATES_DMS = 'COORDINATES_DMS',
  LATITUDE_DMS = 'LATITUDE_DMS',
  LONGITUDE_DMS = 'LONGITUDE_DMS',
  COORDINATES_DDM = 'COORDINATES_DDM',
  LATITUDE_DDM = 'LATITUDE_DDM',
  LONGITUDE_DDM = 'LONGITUDE_DDM',
  FARM_PLOT_SIZE_POINT = 'FARM_PLOT_SIZE_POINT',
  FARM_PLOT_SIZE_COMMA = 'FARM_PLOT_SIZE_COMMA',
}

export enum DatasetErrorEntity {
  SITE = 'SITE',
  DOCUMENT = 'DOCUMENT',
  DATASET = 'DATASET',
}

export type DatasetColumnType = `${DatasetColumn}`;

export type DatasetErrorEntityType = `${DatasetErrorEntity}`;

export type DatasetStatusType = `${DatasetStatus}`;

export interface DatasetError {
  entityId: string;
  entityType: DatasetErrorEntityType;
  errorMessage: string;
}

export interface IDataset {
  id: string;
  title: string;
  status: DatasetStatusType;
  createdTimestamp: Date;
  modifiedTimestamp: Date;
  originCountry: string;
  documents: { id: string; title: string }[];
  /**
   * PartnerCompany base values
   */
  ownedBy: IBasePartnerCompany;
  rawMaterial: IRawMaterial;
  riskStatus: RiskLevelType;
  geocBatches: GeoCBatch[];
  cultivationAreas?: ICultivatedAreas[];
  tableRepresentation?: DatasetTableRepresentation[];
  issues: DatasetError[];
}

export enum DatasetView {
  LIST = 'LIST',
  MAP = 'MAP',
}

export interface IDatasetsResponse {
  datasets: GraphQlConnection<IDataset>;
}

export interface ColumnsData {
  columnName: string;
  columnDataExample: string[]; // Array of strings representing first 5 items as examples
}

export interface DatasetMapping {
  datasetId: string;
  columnsData: ColumnsData[];
}

export interface DatasetError {
  entityId: string;
  entityType: DatasetErrorEntityType;
  errorMessage: string;
}

export type EUDRDatasetCell = {
  value?: string;
  error?: DatasetError[];
  isValid?: boolean;
};

export type EUDRDatasetRow = {
  name: EUDRDatasetCell;
  farmId: EUDRDatasetCell;
  coordinates: EUDRDatasetCell;
  size?: EUDRDatasetCell;
};

export type DatasetTableRepresentation = {
  columnsNames: string[];
  rows: EUDRDatasetRow[];
};

export interface DatasetProcessingError {
  datasetId: string;
  datasetErrors: DatasetError[];
  hasCellErrors: boolean;
}

export interface IProcessDatasetsPayload {
  datasets: IDataset[];
  datasetProcessingErrors?: DatasetProcessingError[];
  datasetMappings?: DatasetMapping[];
}
